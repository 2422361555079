import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Overview from '../views/Overview.vue'
import Trigger from '../views/Trigger.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Home',
    component: Home
  },
  {
    path: '/trigger',
    name: 'Trigger',
    component: Trigger
  },
  {
    path: '/',
    name: 'Overview',
    component: Overview,
    // props: (route) => {
    //   user: userData
    // }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
