<template>
     <div>
        <md-app>
        <md-app-content>
            <div class="md-layout">
                <div class="md-layout-item  md-alignment-left md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100" v-for="builds in Builds" :key="builds">
                    <md-card md-with-hover>
                        <md-card-header>
                            <h3>Ticketnr: {{ builds.ticketnr}} for: {{ builds.platform }}</h3>
                        </md-card-header>
                        <md-card-content>
                            <div><b>Build-Nr.: </b>{{ builds.buildnr  }} | <b>ID:</b> {{ builds.ident }} </div>
                            <div><b>App-Version: </b>{{ builds.appversion }}</div>
                            <div><b>Platform: </b>{{ builds.platform }}</div>
                            <div><b>Ticket-Nr.: </b>{{ builds.ticketnr }}</div>
                            <div><b>Triggered by: </b>{{ builds.username }}</div>
                            <div v-if="builds.commit"><b>Commit: </b><a :href="'https://gitlab.com/bgf/mobile-app/-/commit/'+builds.commit" target="_blank">{{ builds.commit }}</a></div>
                            <div><b>CI Build Time: </b>{{ formatDate(builds.created) }}</div>
                            <md-field>
                                <label for="currentTarget">Target:</label>
                                <md-select :value="builds.targeturl" @md-selected="changedestination($event, builds.ident, builds.ticketnr)">
                                    <md-option v-for="branch in Branches" :key="branch.id"  :value="branch.targeturl" class="md-dense">
                                    {{ branch.caption }}
                                    </md-option>
                                </md-select>
                            </md-field>
                        </md-card-content>
                        <md-card-actions>
                            <md-button class="md-dense md-raised md-accent" @click="deleteBuilds(builds.ident, uservalues.username)"> Delete build </md-button>
                            <md-button class="md-dense md-raised md-primary" :href="'https://'+builds.ident+'.bgf.app.sewingo.de'" target="_blank">Open in Browser</md-button>
                            <md-button class="md-dense md-raised md-primary" :href="baseURL+'/files/'+builds.platform+'/'+builds.buildnr+'/'+builds.filename" target="_blank" v-if="builds.platform == 'android'">Install android</md-button><md-button class="md-dense md-raised md-primary" :href="'itms-services://?action=download-manifest&url='+baseURL+'/files/'+builds.platform+'/'+builds.buildnr+'/'+builds.filename" v-else>Install iOS</md-button>
                        </md-card-actions>
                    </md-card>
                </div>
            </div>

            <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
                <span>{{ snakbar_msg }}</span>
            </md-snackbar>
        </md-app-content>
        </md-app>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

export default {
    name: 'AppTable',
    props: {
        snakbar_msg: String,
        uservalues: Object,
    },
    data () {
            
        return {
            showSnackbar: false,
            position: 'center',
            duration: 2500,
            isInfinity: false,
            Builds: [],
            Branches: [],
            baseURL: location.origin.toString(),
            currBranch: [],
            currentTarget: 'f1805-app-installer-erweiter.app.berg1st.de',
        }
    },
 
    methods: {
        getBuilds: function() {
        if (this.uservalues.username == 'admin') {
          axios.get('/api/builds/all')
            .then(response => {
            this.Builds = response.data;
            })
            .catch(error => console.log(error));
        
        } else {
          axios.get('/api/builds/' + this.uservalues.username)
            .then(response => {
            this.Builds = response.data;
            })
            .catch(error => console.log(error));
        }

          axios.get('/api/targets/all')
            .then(response => {
            this.Branches = response.data;
            })
            .catch(error => console.log(error));
        },
        deleteBuilds: function(ident, username) {
            axios.post('/api/builds/delete/'+ident, { username: username})
                .then(() => {
                    this.showSnackbar="true";
                    this.duration="3000";
                    this.snakbar_msg=`Build for ${ident} deleted` ;
                    this.getBuilds();
            })
                 .catch(error => console.log(error.msg));
        },
        formatDate: function(buildTimestamp){
            return moment(buildTimestamp).format('DD.MM.YYYY - HH:mm');
        },
        changedestination(target_host, id) {
            axios.post('/api/targets/update/'+id, { target_host: target_host })
                 .then(() => {
                    this.showSnackbar="true";
                    this.duration="3000";
                    this.snakbar_msg=`Proxy URL for ID: ${id} changed.` ;
                    
            })
                 .catch(error => console.log(error.msg));
        },
    },
    mounted() {
        this.getBuilds();
    },
}
</script>

<style scoped>
  .md-button {
    margin-left: 6px !important; 
    margin-bottom: 10px !important;
  }
  .md-card {
    margin-bottom: 16px;
  }
  .md-square {
    margin-left: 20px !important;
  }
</style>
