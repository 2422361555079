<template>
  <div class="page-container">
    <md-app>
    <md-app-toolbar class="md-primary">
        <h3 class="md-title" style="flex: 2">BERGFÜRST App Installer</h3>
        <div>User: {{uservalues.username}}</div>
        <md-button @click="logout">Logout</md-button>
    </md-app-toolbar>
    </md-app>
    </div>
</template>

<script>
export default {
  name: 'TheHeader',
  props: {
    uservalues: Object,
  },
  methods: {
    logout() {
        this.$store.dispatch('logout');
        this.$router.push('/login');
    },
  }
}
</script>

<style  scoped>


  .md-app-toolbar {
    background-color: #054063 !important;
  }
</style>