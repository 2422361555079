<template>
             <md-tabs :md-active-tab="'tab-'+$route.path.slice(1)">
                <md-tab id="tab-home" md-label="Overview" to="/" exact></md-tab>
                <md-tab id="tab-trigger" md-label="Trigger App Build" to="/trigger" exact></md-tab>
            </md-tabs>   
</template>

<script>
export default {
  name: 'TabNavigation',
}
</script>