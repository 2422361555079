<template>
  <div class="centered-container">
    <md-content class="md-elevation-3">
      <div class="form">
        <div class="title">
          <div class="md-title">Login</div>
        </div>
        <md-field>
          <label>Username:</label>
          <md-input name="username" v-model="credentials.username" autofocus></md-input>
        </md-field>
        <md-field>
          <label>Password:</label>
          <md-input name="password" v-model="credentials.password" type="password" @keypress.enter="login()" ></md-input>
        </md-field>
        <div class="md-layout md-alignment-space-between-center">
          <md-button class="md-raised md-primary" @click="resetform()">Reset</md-button>
          <md-button class="md-raised md-primary" @click="login()">Log in</md-button>
        </div>
      </div>
    </md-content>

    <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" v-if="msg">
      <span>{{ msg }}</span>
      <md-button class="md-primary" @click="showSnackbar = false; resetform();">close</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService.js';

export default {
  name: 'Home',
  data () {
    return {
      msg: '',
      credentials: {
        username: '',
        password: '',
      },
      showSnackbar: true,
      position: 'center',
      duration: 3000,
      isInfinity: false
    }
  },
  methods: {
    resetform: function () {
      this.credentials.username = '';
      this.credentials.password = '';
      this.msg='';
      this.showSnackbar=true;
    },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login');
      
    }
  },
    async login() {
      try {
        const credentials = {
          username: this.credentials.username,
          password: this.credentials.password
        };
        const response = await AuthService.login(credentials);
        this.msg = response.msg;
        this.showSnackbar=true;

        const token = response.token;
        const user = response.user;

        this.$store.dispatch('login', { token, user });

        this.$router.push('/');
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    }
  }
}
</script>

<style scoped>
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
}
.md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 50vh;
    position: relative;
  }
.md-button .actions {
    margin: 0;
}
.title {
    text-align: center;
    margin-bottom: 10px;
}
</style>
