<template>
    <div>
      <TheHeader :uservalues="User" />
      <TabNavigation />
      <Trigger :uservalues="User" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheHeader from '@/components/TheHeader.vue';
import Trigger from '@/components/Trigger.vue';
import TabNavigation from '@/components/TabNavigation.vue'

export default {
  name: 'Overview',
  components: {
      TheHeader,
      Trigger,
      TabNavigation,
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login');
    }
  },
  computed: {
    ...mapGetters({
      User: "getUser",
      }),
  }
}
</script>