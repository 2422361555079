<template>
    <div>
      <TheHeader :uservalues="User" />
      <TabNavigation />
      <AppTable :uservalues="User" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheHeader from '@/components/TheHeader.vue';
import AppTable from '@/components/AppTable.vue';
import TabNavigation from '@/components/TabNavigation.vue'

export default {
  name: 'Overview',
  data () {
    return {
      user: {
        name: 'alexander',
        email: 'aklatte@bergfuerst.com',
      },
    }
  },
  components: {
      TheHeader,
      AppTable,
      TabNavigation,
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login');
    }
  },
  computed: {
    ...mapGetters({
      User: "getUser",
      }),
  }
}
</script>