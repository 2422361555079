<template>
  <div class="page-container">
      <md-app>
        <md-app-content>
            <md-card>
                <md-card-header>
                    <div class="md-title">Trigger CI Builds</div>
                </md-card-header>
                <md-card-content>
                  <div>
                    <div class="md-layout-item">
                      <md-field>
                        <label for="branch">Branch:</label>
                          <md-select v-model="Branch">
                          <md-option name="branchSelector" v-for="myBranch in Branches" :key="myBranch.id" :disabled="sending" :value="myBranch.name" class="md-dense" >
                            {{ myBranch.name }}
                          </md-option>
                          </md-select>
                      </md-field>
                    <md-button class="md-dense md-raised md-primary" @click="triggerBuild(Branch)">Trigger Build</md-button>         
                    </div>
                  </div>
                </md-card-content>
            </md-card>         
            <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
                <span>{{ snakbar_msg }}</span>
            </md-snackbar>
        </md-app-content>
    </md-app>
    
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Trigger',
  props: {
    uservalues: Object,
    snakbar_msg: String,
  },
  data() {
    return {
      Branches: [],
      showSnackbar: false,
      position: 'center',
      duration: 2500,
      isInfinity: false,
    }
  },
  mounted() {
    fetch('https://gitlab.com/api/v4/projects/22568457/repository/branches?private_token=glpat-ogwrsivgzmGHV2tayCSm')
      .then(res => res.json())
      .then(json => {
        this.Branches = json
      });
  },
  methods: {
    myAlert: function(value) {
      console.log(value);
    },
    triggerBuild: function(selectedBranch) {
      const pipelineTriggerBody = new FormData();
      pipelineTriggerBody.append('ref', selectedBranch); 
      pipelineTriggerBody.append('token', '5091066d58cf6669041df369d5c71a');
      pipelineTriggerBody.append('variables[APP_USERNAME]', this.uservalues.username);
      pipelineTriggerBody.append('variables[APP_BRANCH]', selectedBranch);

      axios
        .post(
          `https://gitlab.com/api/v4/projects/22568457/trigger/pipeline`,
          pipelineTriggerBody
        )
          .then(res => {
            this.showSnackbar="true";
            this.duration="2500";
            this.snakbar_msg=`CI Build triggered.` ;
            console.log('pipeline started:', res.data.web_url);
          })
          .catch(error => {
            this.showSnackbar="true";
            this.duration="2500";
            this.snakbar_msg=`Error occurred` ;
            console.error('errorMessage', error);
          });
      this.Branch = '';
    }
  }
}
</script>

<style  scoped>
  .md-app-toolbar {
    background-color: #054063 !important;
  }
</style>
